import { RenderRichTextData } from 'gatsby-source-contentful/rich-text';

export type RichTextDataWithAsset = RenderRichTextData<
  Queries.ContentfulAsset & { __typename: 'ContentfulAsset' }
>;

export const isRichText = (thing: unknown): thing is RichTextDataWithAsset =>
  typeof thing === 'object' &&
  thing !== null &&
  'raw' in thing &&
  typeof (thing as { raw: string | null }).raw === 'string';
